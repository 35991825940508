// Here you can add other styles

.btn-primary {
    --cui-btn-color: rgba(255, 255, 255, 0.87);
    --cui-btn-bg: #bb8d8e;
    --cui-btn-border-color: #bb8d8e;
    --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
    --cui-btn-hover-bg: #bb8d8ea8;
    --cui-btn-hover-border-color: #bb8d8ea8;
    --cui-btn-focus-shadow-rgb: 75, 58, 223;
    --cui-btn-active-color: rgba(255, 255, 255, 0.87);
    --cui-btn-active-bg: #bb8d8ea8;
    --cui-btn-active-border-color: #bb8d8ea8;
    --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
    --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
    --cui-btn-disabled-bg: #bb8d8e;
    --cui-btn-disabled-border-color: #bb8d8e;
}

.title_table {
    color: #bb8d8e;
    font-size: 25px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-col {
    display: flex;
    align-items: center;
}

.form-input {
    margin-bottom: 10px;
}

.icon_hanlde {
    cursor: pointer;

    & .nav-icon {
        width: 20px;
    }
}

.icon_hanlde {
    cursor: pointer;
}

.bg-primary {
    --cui-bg-opacity: 1;
    // background-color: rgba(50, 31, 219, ) !important;
}

.form-check-input {
    cursor: pointer;
}

.table>tbody {
    margin-bottom: 10px;
}

.table-responsive {
    overflow-x: unset;
}

.block_input {
    margin-bottom: 15px;
}

.accordion-normal {
    margin-bottom: 20px;
}

.flex_row_2 {
    display: flex;
    gap: 20px;
}

.wrap_box_upload_image_child {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

.root-remove {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
}

.wrap_imageUploading {
    align-items: center;
    background-color: #f4f4f4;
    border-radius: 20px;
    cursor: pointer;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 0;
    display: flex;
}

.border-img-dash {
    border: 2px dashed #828282;
}

.ImgUploadIcon {
    margin: 20px 0;
}

.img_upload_box .flex,
.img_upload_box .relative,
.img_upload_box .image-item img {
    width: 100%;
}

.image-item img {
    border-radius: 20px;
    object-fit: cover;
}

.image-item .absolute {
    right: 5px;
    top: 5px;
    position: absolute;
}

.pointer {
    cursor: pointer;
}

.image-item .pointer svg,
.root-remove .pointer svg {
    background: #00000042;
    border-radius: 15px;
    width: 24px;
    height: 24px;
    filter: brightness(1);
    color: #fff;
    padding: 4px;
}

.relative {
    position: relative;
}

.add_image_uploading {
    color: #3460dc;
    cursor: pointer;
    font-size: 18px;
    text-decoration: underline;
}

.list_album_uploads .wrap_imageUploading {
    flex: initial;
    padding: 0;
    width: 150px;
}

.list_album_uploads .wrap_imageUploading .ImgUploadIcon svg {
    width: 50%;
}

.ImgUploadIcon svg {
    font-size: 1em;
    margin: auto;
    width: 80%;
    display: block;
}

.list_album_uploads .wrap_imageUploading .add_image_uploading {
    font-size: 13px;
}

.list_album_uploads .wrap_imageUploading .ImgUploadIcon {
    margin: 0;
}

.list_album_uploads .root-remove {
    justify-content: start;
}

.list_album_uploads img {
    max-width: 150px;
    object-fit: cover;
    width: 150px !important;
}

.loading_image {
    position: absolute;
    background: #08080885;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: var(--white-color);
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}